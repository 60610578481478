import * as React from 'react'
import './person-info.css'

export function PersonInfo(props: Props): JSX.Element {
    const { title, subTitle } = props;
    return (
        <div className="person-info-container">
            <div className="person-info">
                <h1>{title}</h1>
                <h2>{subTitle}</h2>
            </div>
        </div>

    );
}

interface Props {
    title: string
    subTitle: string
}
