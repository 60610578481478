import * as React from 'react'
import './instagram-icon-embed.css'

export const InstragramIcon = () => {
    return (
        <a
            title="Alexo88, Public domain, via Wikimedia Commons"
            href="https://commons.wikimedia.org/wiki/File:Instagram.svg">
            <img
                className="insta-icon"
                width="512"
                alt="Instagram"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Instagram.svg/512px-Instagram.svg.png" />

        </a>
    )
};

