import * as React from 'react'
import './impressum.css'

export function Imprint(props: Props): JSX.Element {

    return (
        <div className="floatingImprint">

            <div className="imprint">
                <h1 className="adsimple-321250853">Impressum</h1>
                <p className="adsimple-321250853">Informationspflicht laut § 5 TMG.</p>
                <p className="adsimple-321250853">
                    <p>Kai Reeh</p>
                    <p className="adsimple-321250853">Felsenstraße 41, <br />70794 Filderstadt, <br />Deutschland</p>
                    <p className="adsimple-321250853">
                        <strong>Tel.:</strong> 01781696020<br />
                        <strong>E-Mail:</strong> <a href="mailto:kaisreeh@gmail.com">kaisreeh@gmail.com</a>
                    </p>
                    <p style={{ marginTop: "15px" }}>Quelle: Erstellt mit dem <a title="Impressum Generator Deutschland" href="https://www.adsimple.de/impressum-generator/" target="_blank" rel="follow noopener noreferrer" style={{ textDecoration: "none" }}>Impressum Generator</a> von AdSimple in Kooperation mit <a href="https://www.slashtechnik.de" target="_blank" rel="follow noopener noreferrer" title="">slashtechnik.de</a>
                    </p>
                    <h2 className="adsimple-321250853">EU-Streitschlichtung</h2>
                    <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a className="adsimple-321250853" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/odr?tid=321250853</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
                    <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                    <h2 className="adsimple-321250853">Haftung für Inhalte dieser Website</h2>
                    <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz <a className="adsimple-321250853" href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321250853" rel="noopener noreferrer" target="_blank">(TMG) §7 (1)</a> sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt. </p>
                    <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
                    <h2 className="adsimple-321250853">Haftung für Links auf dieser Website</h2>
                    <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
                    <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
                    <h2 className="adsimple-321250853">Urheberrechtshinweis</h2>
                    <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
                    <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
                    <h2 className="adsimple-321250853">Bildernachweis</h2>
                    <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
                    <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
                    <ul className="adsimple-321250853">
                        <li className="adsimple-321250853">Fotograf Kai Reeh</li>
                    </ul>
                    <h1 className="adsimple-321250853">Datenschutzerklärung</h1>
                    <h2 className="adsimple-321250853">TLS-Verschlüsselung mit https</h2>
                    <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a className="adsimple-321250853" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321250853" target="_blank" rel="noopener noreferrer">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
                    <p style={{ marginTop: "15px" }}>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland" href="https://www.adsimple.de/datenschutz-generator/" target="_blank" rel="follow noopener noreferrer" style={{ textDecoration: "none" }}>Datenschutz Generator</a> von AdSimple in Kooperation mit <a href="https://www.hashtagmann.de" target="_blank" rel="follow noopener noreferrer" title="">hashtagmann.de</a></p>
                </p>
            </div>
        </div>

    );
}

interface Props { }
