import * as React from 'react'
import './main-layout.css'
import { IonContent, IonRouterOutlet } from "@ionic/react";
import { BackgroundGallery } from './background-gallery';
import { MySocialMedia } from './my-social-media';
import { logoFacebook, logoYoutube } from 'ionicons/icons';
import { SebiImage } from './sebi-image';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router';
import { Imprint } from './impressum';
import { FooterMenu } from './footer-menu';
import { PersonInfo } from './person-info';
import { Contact } from './contact';
import { InstragramIcon } from './instagram-icon-embed';

export function MainLayout(props: Props): JSX.Element {

    let images = []
    for (let i = 1; i <= 6; i++) {
        images.push({ url: `assets/instagram/${i}.jpg` })
    }


    const mySocialMedia = [
        { name: 'Instagram', url: 'https://www.instagram.com/sebbtab/', embed: <InstragramIcon /> },
        { name: 'Facebook', url: 'https://www.facebook.com/SebastianReehRacing', icon: logoFacebook, iconColor: '#4267B2' },
        { name: 'Youtube', url: 'https://www.youtube.com/channel/UCOh5Z65eczCY08WcpR47I1Q', icon: logoYoutube, iconColor: '#FF0000' },
        { name: 'Raceroom', url: 'https://game.raceroom.com/users/sebtab/', iconPath: 'assets/icon/auto_trace_raceroom.svg', },
    ]

    const pages = [
        { url: "/", label: "Home" },
        { url: "/impressum", label: "Impressum" },
        { url: "https://www.facebook.com/SebastianReehRacing", label: "Kontakt" }
    ]

    const title = "Sebastian Reeh"
    const subTitle = "Rennfahrer"

    return (
        <IonContent>
            <div className="root">

                <div className="background">
                    <div className="background-top">
                        <BackgroundGallery images={images} speed={14000} />
                    </div>
                    <div className="background-bottom"></div>
                </div>

                <div className="mainScroll">
                    <IonReactRouter>
                        <IonRouterOutlet id="main" class="router">

                            <Route path="/impressum" exact>
                                <Imprint />
                            </Route>
                            <Route path="/kontakt" exact>
                                <Contact />
                            </Route>
                            <Route path="/" exact>
                                <div className="floatingContent">
                                    <PersonInfo title={title} subTitle={subTitle} />
                                    <MySocialMedia media={mySocialMedia} />
                                </div>
                            </Route>

                        </IonRouterOutlet>
                    </IonReactRouter>
                </div>
                <FooterMenu pages={pages} />


                <div className="person">
                    <SebiImage />
                </div>
            </div>

        </IonContent>
    );
}

interface Props { }
