import * as React from 'react'
import { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './background-gallery.css'

export function BackgroundGallery(props: Props): JSX.Element {
    const { images, speed = 5000 } = props;
    const [count, setCount] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < images.length - 1) {
                setCount(count + 1)
                return
            }

            setCount(0)
        }, speed);

        return () => clearInterval(interval);
    }, [count, speed, images.length]);

    return (
        <>
            <div className="fakeImage" />
            <div className="transition">
                <TransitionGroup>
                    <CSSTransition
                        timeout={10000}
                        classNames="imageout"
                        key={count}
                    >
                        <div className="imageWrapper">
                            <img
                                className="image"
                                src={images[count].url}
                                alt=""
                            />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </>
    );
}

interface Props {
    images: Image[]
    speed?: number
}

interface Image {
    url: string
}
