import * as React from 'react'

export function SebiImage(props: Props): JSX.Element {

    return (
        <img src="assets/icon/sebi_x_small.png" alt="Person" />
    );
}

interface Props {
}
