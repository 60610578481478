import * as React from 'react'
import './my-social-media.css'
import { IonIcon } from "@ionic/react";

export function MySocialMedia(props: Props): JSX.Element {
    const { media } = props;

    return (
        <div className="media">
            {media.map((m) => {
                return (
                    <a href={m.url} target="_blank" rel="noopener noreferrer" title={m.name} aria-label={m.name}>
                        {renderIcon(m.icon, m.iconPath, m.iconColor, m.embed, m.name)}
                    </a>
                )
            })}
        </div>
    );
}

interface Props {
    media: SocialMedia[]
}

interface SocialMedia {
    url: string
    icon?: string
    iconPath?: string
    iconColor?: string
    embed?: JSX.Element
    name?: string
}

function renderIcon(
    icon?: string,
    iconPath?: string,
    iconColor?: string,
    embed?: JSX.Element,
    name?: string
) {
    if (icon) {
        return (
            <IonIcon slot="start" icon={icon} size="2rem" style={{ color: iconColor }} title={name} ariaLabel={name} />
        )
    }

    if (iconPath) {
        return (
            <IonIcon slot="start" src={iconPath} size="2rem" style={{ color: iconColor }} title={name} ariaLabel={name} />
        )
    }

    if (embed) {
        return embed;
    }
}
