import * as React from 'react'
import './footer-menu.css'


export function FooterMenu(props: Props): JSX.Element {
    const { pages } = props;

    return (
        <div className="footer-menu">
            {
                pages.map(({ label, url }) => {
                    return (
                        <a href={url} className="menu-link">
                            {label}
                        </a>
                    )
                })
            }
        </div>
    );
}

interface Props {
    pages: { url: string, label: string }[]
}
